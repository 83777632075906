<template>
  <div style="width: 100%">
    <div class="back-img">
      <div style="font-size: 50px; color: #fff">STEERING COMMITTEE</div>
    </div>

    <div class="text-zone">
      <el-row>
        <el-col :md="8" :sm="12" v-for="colData in data">
          <div class="info-card" v-for="item in colData">
            <div class="title">{{item.title}}</div>
            <a>{{item.name}}</a>            
            <div>{{item.affi}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steering",
  data() {
    return {
      data:
          [
            [
              {
                title: 'Chairpersons',
                name: 'Yinbiao Shu',
                affi: 'China Huaneng Group Co., Ltd.'
              },
              {
                title: '',
                name: 'Jassica Bian',
                affi: 'Grid-X Partners'
              },
              {
                title: 'Vice-Chairpersons',
                name: 'Mingshan Li',
                affi: 'Deputy President of CSEE'
              },
              {
                title: 'Members',
                name: 'Xiaoxin Zhou',
                affi: 'China Electric Power Research Institute'
              },
              {
                title: '',
                name: 'Yusheng Xue',
                affi: 'State Grid Electric Power Research Institute'
              },
            ],
            [
              
              
              {
                title: '',
                name: 'Mohammad Shahidehpour',
                affi: 'Illinois Institute of Technology'
              },
              {
                title: '',
                name: 'Licheng Li',
                affi: 'China Southern Power Grid Co., Ltd.'
              },
              {
                title: '',
                name: 'Yulong Ding',
                affi: 'University of Birmingham'
              },
              {
                title: '',
                name: 'Saifur Rahman',
                affi: 'Integral University'
              },
            ],
            [

              
              {
                title: '',
                name: 'Zi-Qiang Zhu',
                affi: 'The University of Sheffield'
              },
              {
                title: '',
                name: 'Goran Strbac',
                affi: 'Imperial College London'
              },
              {
                title: '',
                name: 'Gang Wang',
                affi: 'Secretary General of CSEE'
              },
              {
                title: '',
                name: 'Chengxiang Wang',
                affi: 'Southeast University'
              },
            ]
          ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/styles/committees";
</style>
